import {useState} from 'react';
import {Button, Container, Row, Col, Form, ListGroup, Image} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {useLoginMutation} from "../store/apiSlice";
import {setTokens} from "../store/authSlice"
import Loading from "./Spinner";
import {useDispatch} from "react-redux";
import logo from "../logo.png";
import {CircleFlag} from 'react-circle-flags';

const AuthenticationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // set component state
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  // use mutation hook to get tokens
  const [login, {isLoading, error}] = useLoginMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(JSON.stringify({"username": username, "password": password})).unwrap()
      .then(data => {
        localStorage.setItem('accessToken', data.token);
        localStorage.setItem('agent', data.agent);
        localStorage.setItem('agentName', data.agentName);
        dispatch(setTokens(data));
        const agent = data.agent;
        navigate(`/${agent}`);
      })
      .catch(rejected => {
        navigate("/notlogged");
        console.error("error", rejected);
      });
  };

  return (
    <main>
      <Container className="start-page" fluid>
        <Row className="login-wrapper">
          <Col className="login-form">
            <Image
              src={logo}
              alt="alesta logo"
              className="login-logo"
            />
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="userName">
                <Form.Control
                  type="text"
                  value={username}
                  placeholder="имя пользователя"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Control
                  type="password"
                  value={password}
                  placeholder="пароль"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className="enter-btn">
                  {isLoading ? <Loading className={"spinner"}/> : <Button variant="primary" type="submit">войти</Button>}
              </div>
            </Form>
            <ListGroup className="contacts-list">
              <ListGroup.Item>
                <i className="bi bi-telephone"></i>
                 <span className="image-container">
               <CircleFlag countryCode="by" alt="BY"/>
              </span>
                <a href="tel:+375336262122">+37533 62 62 122</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <i className="bi bi-telephone"></i>
                <span className="image-container">
                <CircleFlag countryCode="lt" alt="LT"/>
              </span>
                <a href="tel: +37061618111">+370 616 18 111</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <i className="bi bi-telephone"></i>
                <span className="image-container">
                <CircleFlag countryCode="ru" alt="RU"/>
              </span>
                <a href="tel:+79240779215">+792 407 792 15</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <i className="bi bi-telephone"></i>
                <span className="image-container">
                <CircleFlag countryCode="pl" alt="PL"/>
              </span>
                <a href="tel:+48459567540">+48 459 567 540</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <i className="bi bi-envelope"></i>
                <a href="mailto:mail@alesta.group">
                  mail@alesta.group
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <i className="bi bi-instagram"></i>
                <a href="https://www.instagram.com/alesta_custom_servis/" target="_blank" rel="noreferrer">
                  @alesta_custom_servis
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <i className="bi bi-telegram"></i>
                <a href="https://t.me/alestagroup" target="_blank" rel="noreferrer">
                  alestagroup
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Row>
          {error ? <span>{error.data}</span> : <span> </span>}
        </Row>
      </Container>
    </main>
  )
};

export default AuthenticationForm;

