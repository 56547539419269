import {Row, Col, ListGroup} from 'react-bootstrap';
import {CircleFlag} from 'react-circle-flags';
import NavBar from "./NavBar";

const Header = () => {
  return (
    <header>
      <Row>
        <Col className="header">
          <NavBar/>
          <ListGroup horizontal="sm" className="contacts">
            <ListGroup.Item>
              <div className="contacts --our-contacts">
                НАШИ КОНТАКТЫ:
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
               <CircleFlag countryCode="by" alt="BY"/>
              </span>
              <a href="tel:+375336262122">+375336262122</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
                <CircleFlag countryCode="lt" alt="LT"/>
              </span>
              <a href="tel: +37061618111">+37061618111</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
                <CircleFlag countryCode="ru" alt="RU"/>
              </span>
              <a href="tel:+79585815149">+79240779215</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
                <CircleFlag countryCode="pl" alt="PL"/>
              </span>
              <a href="tel:+48459567540">+48459567540</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-envelope"></i>
              <a href="mailto:mail@alesta.group">
                mail@alesta.group
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-instagram"></i>
              <a href="https://www.instagram.com/alesta_custom_servis/" target="_blank" rel="noreferrer">
                @alesta_custom_servis
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telegram"></i>
              <a href="https://t.me/alestagroup" target="_blank" rel="noreferrer">
                alestagroup
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </header>
  )
};

export default Header;